import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Circle from "../components/canvas/circle"

const Sketchbook = () => (
  <Layout>
    <Seo title="Page two" />
    <p>sketchbook</p>
    <Link to="/">homepage</Link>
    <Circle />
  </Layout>
)

export default Sketchbook
